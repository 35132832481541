import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "brain_AI" }, [
      _createElementVNode("path", {
        id: "Union",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M23.7702 24.8817C23.7704 24.853 23.7705 24.8242 23.7705 24.7954C23.7705 24.7666 23.7704 24.7378 23.7702 24.7091L23.7702 13.4221C23.7706 13.3881 23.7708 13.3541 23.7708 13.32C23.7708 13.2859 23.7706 13.2518 23.7702 13.2179L23.7702 12.9102L23.7604 12.9102C23.5472 8.7246 20.0859 5.39649 15.8473 5.39649C11.4713 5.39649 7.92383 8.94395 7.92383 13.32C7.92383 13.4086 7.92529 13.4969 7.92817 13.5848C3.30953 15.2151 -2.4867e-06 19.6187 -3.39181e-06 24.7954C-4.29845e-06 29.9808 3.32068 34.3906 7.95152 36.0142C7.93318 36.2341 7.92383 36.4566 7.92383 36.6813C7.92383 41.0573 11.4713 44.6048 15.8473 44.6048C20.2234 44.6048 23.7708 41.0573 23.7708 36.6813C23.7708 36.6471 23.7706 36.613 23.7702 36.5789L23.7702 24.8817Z",
        fill: "#212529"
      }),
      _createElementVNode("path", {
        id: "Subtract",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M26.2292 34.7681L26.2292 36.5774C26.2287 36.6113 26.2285 36.6453 26.2285 36.6794C26.2285 36.7134 26.2287 36.7474 26.2292 36.7813L26.2292 37.0892H26.2389C26.4522 41.2747 29.9134 44.6029 34.152 44.6029C38.528 44.6029 42.0755 41.0554 42.0755 36.6794C42.0755 36.5907 42.0741 36.5024 42.0712 36.4144C46.6898 34.7842 49.9993 30.3805 49.9993 25.2038C49.9993 20.0185 46.6787 15.6087 42.0478 13.985C42.0662 13.7651 42.0755 13.5427 42.0755 13.318C42.0755 8.942 38.528 5.39453 34.152 5.39453C29.776 5.39453 26.2285 8.942 26.2285 13.318C26.2285 13.3522 26.2287 13.3863 26.2292 13.4203L26.2292 15.2316H30.6452C31.2937 13.6295 32.8644 12.4993 34.699 12.4993C37.1134 12.4993 39.0706 14.4566 39.0706 16.8709C39.0706 19.2853 37.1134 21.2425 34.699 21.2425C32.8644 21.2425 31.2938 20.1124 30.6453 18.5103H26.2292L26.2292 23.634H37.7852C38.3588 21.8885 40.0018 20.6282 41.9392 20.6282C44.3536 20.6282 46.3108 22.5855 46.3108 24.9998C46.3108 27.4142 44.3536 29.3714 41.9392 29.3714C40.2109 29.3714 38.7168 28.3684 38.0073 26.9127H26.2292L26.2292 31.4895H30.6452C31.2937 29.8873 32.8644 28.7572 34.699 28.7572C37.1134 28.7572 39.0706 30.7144 39.0706 33.1287C39.0706 35.5431 37.1134 37.5003 34.699 37.5003C32.8644 37.5003 31.2938 36.3702 30.6453 34.7681H26.2292ZM41.9392 27.1856C43.1464 27.1856 44.125 26.207 44.125 24.9998C44.125 23.7926 43.1464 22.814 41.9392 22.814C40.732 22.814 39.7534 23.7926 39.7534 24.9998C39.7534 26.207 40.732 27.1856 41.9392 27.1856ZM36.8848 16.8709C36.8848 18.0781 35.9062 19.0567 34.699 19.0567C33.4919 19.0567 32.5133 18.0781 32.5133 16.8709C32.5133 15.6637 33.4919 14.6851 34.699 14.6851C35.9062 14.6851 36.8848 15.6637 36.8848 16.8709ZM34.699 35.3145C35.9062 35.3145 36.8848 34.3359 36.8848 33.1287C36.8848 31.9216 35.9062 30.9429 34.699 30.9429C33.4919 30.9429 32.5133 31.9216 32.5133 33.1287C32.5133 34.3359 33.4919 35.3145 34.699 35.3145Z",
        fill: "#F06F02"
      })
    ], -1)
  ])))
}
export default { render: render }